import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, IconButton, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import './Navigation.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

var Navigation = function(props, ref) {
  var t = useTranslation().t;
  var i18n = useTranslation().i18n;
  var [isOpen, setIsOpen] = useState(false);
  var [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  var languageContainerRef = useRef(null);

  var [hoveredItem, setHoveredItem] = useState(null);

  useEffect(function() {
    function handleClickOutside(event) {
      if (languageContainerRef.current && !languageContainerRef.current.contains(event.target)) {
        setLanguageMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return function() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setLanguageMenuOpen]);

  var toggleDrawer = function(open) {
    return function(event) {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setIsOpen(open);
    };
  };

  var changeLanguage = function(lang) {
    i18n.changeLanguage(lang);
    setLanguageMenuOpen(false);
  };

  var handleMouseEnter = function(item) {
    setHoveredItem(item);
  };

  var handleMouseLeave = function() {
    setHoveredItem(null);
  };

  var menuItems = [
    { text: 'nav.home', path: '/' },
    { text: 'nav.saintMatrona', path: '/saint-matrona' },
    { text: 'nav.patriarchate', path: '/patriarcado' },
    { text: 'nav.schedule', path: '/schedule' },
    {
      text: 'nav.churches',
      items: [
        { text: 'nav.stMatronaChurch', path: '/churches/stMatrona' },
        { text: 'nav.trinityChurch', path: '/churches/trinity' }
      ]
    },
    { text: 'nav.events', path: '/events' },
    { text: 'nav.history', path: '/history' },
    { text: 'nav.calendar', path: '/calendar' },
    { 
      text: 'nav.audiovisual', 
      items: [
        { text: 'nav.gallery', path: '/gallery' },
        { text: 'nav.audiovisualGallery', path: '/audiovisual' }
      ]
    },
  ];

  var renderMenuItem = function(item) {
    if (item.items) {
      return React.createElement(
        React.Fragment,
        { key: item.text },
        React.createElement(ListItem, {
          button: true,
          onMouseEnter: function() { handleMouseEnter(item.text); },
          onMouseLeave: handleMouseLeave
        },
          React.createElement(ListItemText, { primary: t(item.text) }),
          hoveredItem === item.text ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null)
        ),
        React.createElement(Collapse, { in: hoveredItem === item.text, timeout: 'auto', unmountOnExit: true },
          React.createElement(List, { 
            component: 'div', 
            disablePadding: true,
            onMouseEnter: function() { handleMouseEnter(item.text); },
            onMouseLeave: handleMouseLeave
          },
            item.items.map(function(subItem) {
              return React.createElement(ListItem, {
                key: subItem.text,
                component: Link,
                to: subItem.path,
                onClick: toggleDrawer(false),
                style: { paddingLeft: 32 }
              }, React.createElement(ListItemText, { primary: t(subItem.text) }));
            })
          )
        )
      );
    } else {
      return React.createElement(ListItem, {
        button: true,
        key: item.text,
        component: Link,
        to: item.path,
        onClick: toggleDrawer(false),
        className: 'menu-item'
      },
        React.createElement(ListItemText, { primary: t(item.text) })
      );
    }
  };

  return (
    React.createElement('div', { className: 'navigation-container', ref: ref },
      React.createElement(IconButton, {
        onClick: toggleDrawer(true),
        className: 'nav-icon',
        'aria-label': 'menu'
      }, React.createElement(MenuIcon, null)),
      React.createElement('div', { className: 'language-container', ref: languageContainerRef },
        React.createElement(IconButton, {
          onClick: function() { setLanguageMenuOpen(!languageMenuOpen); },
          className: 'nav-icon',
          'aria-label': 'language'
        }, React.createElement(LanguageIcon, null)),
        languageMenuOpen && React.createElement('div', { className: 'language-menu' },
          React.createElement('button', { onClick: function() { changeLanguage('es'); } }, 'ES'),
          React.createElement('button', { onClick: function() { changeLanguage('ru'); } }, 'RU')
        )
      ),
      React.createElement(Drawer, { 
        anchor: 'left', 
        open: isOpen, 
        onClose: toggleDrawer(false),
        classes: {
          paper: 'drawer-paper'
        },
        PaperProps: {
          style: {
            background: 'linear-gradient(to bottom, var(--gold-light), #e6d9b8)',
          }
        }
      },
        React.createElement('div', { className: 'drawer-header' },
          React.createElement('h2', null, t('nav.menu'))
        ),
        React.createElement(List, { 
          className: 'menu-list',
          style: { background: 'transparent' }
        },
          menuItems.map(renderMenuItem)
        )
      )
    )
  );
};

Navigation = forwardRef(Navigation);

export default Navigation;