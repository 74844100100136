import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import esTranslations from './locales/es.json';
import ruTranslations from './locales/ru.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: { translation: esTranslations },
      ru: { translation: ruTranslations }
    },
    lng: 'es', // idioma por defecto
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false // Esto es importante para evitar problemas de renderizado
    }
  });

export default i18n;
