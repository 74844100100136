var React = require('react');
var useTranslation = require('react-i18next').useTranslation;
var FaMapMarkerAlt = require('react-icons/fa').FaMapMarkerAlt;
var FaWaze = require('react-icons/fa').FaWaze;
var FaApple = require('react-icons/fa').FaApple;
var MapLink = require('./MapLink');

function Map({ churchId }) {
  var t = useTranslation().t;

  var churchInfo = {
    stMatrona: {
      title: t('parish.StMatronaChurch'),
      locationNote: t('schedule.locationNote'),
      mapSrc: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3901.4924561234567!2d-77.02791610995911!3d-12.134100901043952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDA4JzAzLjMiUyA3N8KwMDEnNDAuOSJX!5e0!3m2!1ses-419!2spe',
      googleHref: 'https://www.google.com/maps?q=loc:-12.1341009,-77.0279161',
      wazeHref: 'https://waze.com/ul?ll=-12.1341009,-77.0279161&navigate=yes',
      appleHref: 'http://maps.apple.com/?ll=-12.1341009,-77.0279161'
    },
    trinity: {
      title: t('parish.trinityChurch'),
      locationNote: '',
      mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.492456123456!2d-77.0655355!3d-12.083983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c906516b2069%3A0xe760b5d8b122540c!2sIglesia%20Ortodoxa%20de%20la%20Sant%C3%ADsima%20Trinidad!5e0!3m2!1ses-419!2spe',
      googleHref: 'https://www.google.com/maps?q=loc:-12.083983,-77.0655355',
      wazeHref: 'https://waze.com/ul?ll=-12.083983,-77.0655355&navigate=yes',
      appleHref: 'http://maps.apple.com/?ll=-12.083983,-77.0655355'
    }
  };

  var church = churchInfo[churchId];

  return React.createElement(React.Fragment, null,
    React.createElement('h3', { 
      className: 'map-title', 
      style: { 
        lineHeight: '1', 
        marginBottom: '1.1em',
        marginTop: '1.5em' // Añadimos margen superior
      }
    }, t('map.titlePrefix') + ' ' + church.title),
    church.locationNote && React.createElement('p', { className: 'location-note' }, church.locationNote),
    React.createElement('div', { style: { height: '400px', width: '100%', marginBottom: '20px' } },
      React.createElement('iframe', {
        width: '100%',
        height: '100%',
        frameBorder: '0',
        style: { border: 0 },
        src: church.mapSrc,
        allowFullScreen: true
      })
    ),
    React.createElement(MapLink, {
      googleHref: church.googleHref,
      googleIcon: FaMapMarkerAlt,
      wazeHref: church.wazeHref,
      wazeIcon: FaWaze,
      appleHref: church.appleHref,
      appleIcon: FaApple,
      label: t('schedule.openInMaps')
    })
  );
}

module.exports = Map;
