var React = require('react');
var useTranslation = require('react-i18next').useTranslation;

function MapLink(props) {
  var t = useTranslation().t;

  function getGoogleMapsUrl(url, forApp) {
    var coordinates;
    
    // Intentar extraer coordenadas de diferentes formatos de URL
    var matches = [
      url.match(/[?&]q=loc:([-\d.]+),([-\d.]+)/),
      url.match(/[@!](-?\d+\.\d+),(-?\d+\.\d+)/),
      url.match(/[?&]q=([-\d.]+),([-\d.]+)/)
    ];

    for (var i = 0; i < matches.length; i++) {
      if (matches[i]) {
        coordinates = matches[i][1] + ',' + matches[i][2];
        break;
      }
    }

    if (coordinates) {
      // Si encontramos coordenadas, las usamos directamente
      return forApp 
        ? 'comgooglemaps://?q=' + coordinates 
        : 'https://www.google.com/maps/search/?api=1&query=' + coordinates;
    } else {
      // Si no encontramos coordenadas, usamos la URL completa como consulta
      return forApp
        ? 'comgooglemaps://?q=' + encodeURIComponent(url)
        : 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(url);
    }
  }

  function openGoogleMaps(e) {
    e.preventDefault();
    var appUrl = getGoogleMapsUrl(props.googleHref, true);
    var webUrl = getGoogleMapsUrl(props.googleHref, false);

    // Detectar si estamos en un dispositivo móvil
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Comportamiento existente para dispositivos móviles
      var now = Date.now();
      var timeoutId;

      window.location.href = appUrl;

      function openWebVersion() {
        clearTimeout(timeoutId);
        if (Date.now() - now > 1000) {
          // La app se abrió, no hacemos nada
          return;
        }
        // La app no se abrió, abrimos la versión web en una nueva ventana
        window.open(webUrl, '_blank');
      }

      timeoutId = setTimeout(openWebVersion, 1000);
      window.addEventListener('visibilitychange', openWebVersion, { once: true });
    } else {
      // En PC, abrimos directamente en una nueva ventana
      window.open(webUrl, '_blank');
    }
  }

  function getAppleMapsUrl(url) {
    var coordinates;
    
    // Intentar extraer coordenadas de diferentes formatos de URL
    var matches = [
      url.match(/[?&]q=loc:([-\d.]+),([-\d.]+)/),
      url.match(/[@!](-?\d+\.\d+),(-?\d+\.\d+)/),
      url.match(/[?&]q=([-\d.]+),([-\d.]+)/)
    ];

    for (var i = 0; i < matches.length; i++) {
      if (matches[i]) {
        coordinates = matches[i][1] + ',' + matches[i][2];
        break;
      }
    }

    if (coordinates) {
      // Si encontramos coordenadas, las usamos directamente
      return 'maps://maps.apple.com/?q=' + coordinates;
    } else {
      // Si no encontramos coordenadas, usamos la URL completa como consulta
      return 'maps://maps.apple.com/?q=' + encodeURIComponent(url);
    }
  }

  function openAppleMaps(e) {
    e.preventDefault();
    var appleMapsUrl = getAppleMapsUrl(props.appleHref);
    var webUrl = 'https://maps.apple.com/?q=' + encodeURIComponent(props.appleHref);

    // Intenta abrir Apple Maps
    var now = Date.now();
    var timeoutId;

    window.location.href = appleMapsUrl;

    // Función para abrir la versión web en una nueva ventana
    function openWebVersion() {
      clearTimeout(timeoutId);
      if (Date.now() - now > 1000) {
        // La app se abrió, no hacemos nada
        return;
      }
      // La app no se abrió, abrimos la versión web en una nueva ventana
      window.open(webUrl, '_blank');
    }

    // Establecemos un timeout para verificar si la app se abrió
    timeoutId = setTimeout(openWebVersion, 1000);

    // También manejamos el caso en que el usuario vuelve rápidamente al navegador
    window.addEventListener('visibilitychange', openWebVersion, { once: true });
  }

  return React.createElement('div', {
    style: { 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#f5f5dc',
      padding: '15px',
      borderRadius: '8px',
      margin: '15px 0'
    }
  },
    React.createElement('p', {
      style: {
        marginBottom: '10px',
        fontSize: '14px',
        textAlign: 'center'
      }
    }, t('schedule.clickToOpen')),
    React.createElement('div', {
      style: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%'
      }
    },
      [
        { name: 'googleMaps', icon: props.googleIcon, onClick: openGoogleMaps, color: 'red' },
        { name: 'waze', icon: props.wazeIcon, href: props.wazeHref, color: 'blue' },
        { name: 'appleMaps', icon: props.appleIcon, onClick: openAppleMaps, color: 'black' }
      ].map(function(app) {
        return React.createElement('a', {
          key: app.name,
          href: app.href,
          onClick: app.onClick,
          target: '_blank',
          rel: 'noopener noreferrer',
          style: {
            textDecoration: 'none',
            color: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        },
          React.createElement(app.icon, { size: 30, color: app.color }),
          React.createElement('span', {
            style: {
              fontSize: '12px',
              marginTop: '5px',
              textAlign: 'center'
            }
          }, t('schedule.' + app.name))
        );
      })
    )
  );
}

module.exports = MapLink;
