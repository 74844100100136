var React = require('react');
var useTranslation = require('react-i18next').useTranslation;
var Map = require('../../components/Map');

function Schedule() {
  var t = useTranslation().t;

  return React.createElement('div', { className: 'schedule-container' },
    React.createElement('h1', { className: 'schedule-title' }, t('schedule.title')),
    React.createElement('table', { className: 'schedule-table' },
      React.createElement('thead', null,
        React.createElement('tr', null,
          React.createElement('th', null, t('schedule.service')),
          React.createElement('th', null, t('schedule.dayAndTime')),
          React.createElement('th', null, t('schedule.location'))
        )
      ),
      React.createElement('tbody', null,
        React.createElement('tr', null,
          React.createElement('td', null, t('schedule.wednesdayCatechism')),
          React.createElement('td', null, t('days.wednesday') + ' 19:30'),
          React.createElement('td', null, t('parish.trinityChurch'))
        ),
        React.createElement('tr', { className: 'alternate-row' },
          React.createElement('td', null, t('schedule.fridayAppointment')),
          React.createElement('td', null, [
            t('days.friday') + ' ' + t('schedule.afternoon'),
            React.createElement('br', { key: 'br' }),
            '(' + t('schedule.byAppointment') + ')'
          ]),
          React.createElement('td', null, t('parish.trinityChurch'))
        ),
        React.createElement('tr', null,
          React.createElement('td', null, t('schedule.saturdayPrayer')),
          React.createElement('td', null, t('days.saturday') + ' 10:00'),
          React.createElement('td', null, t('parish.StMatronaChurch'))
        ),
        React.createElement('tr', { className: 'alternate-row' },
          React.createElement('td', null, t('schedule.saturdayVigil')),
          React.createElement('td', null, t('days.saturday') + ' 18:00'),
          React.createElement('td', null, t('parish.trinityChurch'))
        ),
        React.createElement('tr', null,
          React.createElement('td', null, t('schedule.sundayMatins')),
          React.createElement('td', null, t('days.sunday') + ' 10:00'),
          React.createElement('td', null, t('parish.trinityChurch'))
        ),
        React.createElement('tr', { className: 'alternate-row' },
          React.createElement('td', null, t('schedule.sundayLiturgy')),
          React.createElement('td', null, t('days.sunday') + ' 11:00'),
          React.createElement('td', null, t('parish.trinityChurch'))
        )
      )
    ),
    React.createElement(Map, { churchId: 'stMatrona' }),
    React.createElement(Map, { churchId: 'trinity' })
  );
}

module.exports = Schedule;
