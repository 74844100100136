var React = require('react');
var useTranslation = require('react-i18next').useTranslation;
var PageTitle = require('../../../components/PageTitle');
var ContentSection = require('../../../components/ContentSection');
var CaptionedImage = require('../../../components/CaptionedImage');
var StyledList = require('../../../components/StyledList');
var Map = require('../../../components/Map');

function TrinityChurchPage() {
  var t = useTranslation().t;

  return React.createElement('div', { className: 'trinity-church-page' },
    React.createElement(PageTitle, null, t('churches.trinity.title')),
    React.createElement(ContentSection, null,
      React.createElement('p', null, t('churches.trinity.description')),
      React.createElement(CaptionedImage, {
        src: '/images/SantisimaTrinidad.jpg',
        alt: t('churches.trinity.churchImageAlt'),
        caption: t('churches.trinity.churchImageCaption')
      })
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('churches.trinity.history')),
      React.createElement('p', null, t('churches.trinity.historyText'))
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('churches.trinity.iconostasis')),
      React.createElement('p', null, t('churches.trinity.iconostasisText')),
      React.createElement(CaptionedImage, {
        src: '/images/iconostasio.jpg',
        alt: t('churches.trinity.iconostasisImageAlt'),
        caption: t('churches.trinity.iconostasisImageCaption')
      })
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('churches.trinity.community')),
      React.createElement('p', null, t('churches.trinity.communityText')),
      React.createElement(CaptionedImage, {
        src: '/images/comunidad-liturgia.jpg',
        alt: t('churches.trinity.communityImageAlt'),
        caption: t('churches.trinity.communityImageCaption')
      })
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('churches.trinity.schedule')),
      React.createElement(StyledList, {
        items: t('churches.trinity.scheduleList', { returnObjects: true }) || []
      })
    ),
    React.createElement(Map, { churchId: 'trinity' })
  );
}

module.exports = TrinityChurchPage;
