var React = require('react');
var useTranslation = require('react-i18next').useTranslation;
var Map = require('../../../components/Map');

function StMatronaChurchPage() {
  var t = useTranslation().t;

  return React.createElement('div', { className: 'church-page' },
    React.createElement('h1', null, t('churches.stMatrona.title')),
    React.createElement('div', { className: 'church-content' },
      React.createElement('p', null, t('churches.stMatrona.description')),
      React.createElement('h2', null, t('churches.stMatrona.history')),
      React.createElement('p', null, t('churches.stMatrona.historyText')),
      React.createElement('h2', null, t('churches.stMatrona.architecture')),
      React.createElement('p', null, t('churches.stMatrona.architectureText')),
      React.createElement('h2', null, t('churches.stMatrona.relics')),
      React.createElement('p', null, t('churches.stMatrona.relicsText')),
      React.createElement('h2', null, t('churches.stMatrona.schedule')),
      React.createElement('ul', null,
        React.createElement('li', null, t('churches.stMatrona.scheduleList.0'))
      )
    ),
    React.createElement(Map, { 
      churchId: 'stMatrona',
      latitude: -12.134100901043952,
      longitude: -77.02791610995911
    })
  );
}

module.exports = StMatronaChurchPage;
