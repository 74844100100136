var React = require('react');
var PropTypes = require('prop-types');

function StyledList(props) {
  var items = Array.isArray(props.items) ? props.items : [];

  return React.createElement('div', { className: 'styled-list-container' },
    React.createElement('ul', { className: 'styled-list' },
      items.map(function(item, index) {
        return React.createElement('li', { key: index }, item);
      })
    )
  );
}

// Validar el tipo de props.items
StyledList.propTypes = {
  items: PropTypes.array
};

module.exports = StyledList;
