var React = require('react');
var useTranslation = require('react-i18next').useTranslation;
var PageTitle = require('../../components/PageTitle');
var ContentSection = require('../../components/ContentSection');
var CaptionedImage = require('../../components/CaptionedImage');
var StyledList = require('../../components/StyledList');

function PatriarchatePage() {
  var t = useTranslation().t;

  return React.createElement('div', { className: 'patriarchate-page' },
    React.createElement(PageTitle, null, t('patriarchate.title')),
    React.createElement(ContentSection, null,
      React.createElement(CaptionedImage, {
        src: '/images/catedral_cristo_salvador.jpg',
        alt: t('patriarchate.catedralCristoSalvador'),
        caption: t('patriarchate.catedralCristoSalvador')
      }),
      React.createElement('p', null, t('patriarchate.intro'))
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('patriarchate.historyTitle')),
      React.createElement('div', { className: 'timeline' },
        (t('patriarchate.timeline', { returnObjects: true }) || []).map(function(item, index) {
          return React.createElement('div', { key: index, className: 'timeline-item' },
            React.createElement('h3', null, item.year),
            React.createElement('p', null, item.event),
            item.image && React.createElement(CaptionedImage, {
              src: item.image,
              alt: item.event,
              caption: item.caption || (item.year + ': ' + item.event)
            })
          );
        })
      )
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('patriarchate.structureTitle')),
      React.createElement(CaptionedImage, {
        src: '/images/patriarca_cirilo.jpg',
        alt: t('patriarchate.patriarcaCirilo'),
        caption: t('patriarchate.patriarcaCirilo')
      }),
      React.createElement(StyledList, {
        items: t('patriarchate.structure', { returnObjects: true }) || []
      })
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('patriarchate.flourishingTitle')),
      React.createElement(CaptionedImage, {
        src: '/images/monasterio_trinidad_san_sergio.jpg',
        alt: t('patriarchate.monasterioTrinidadSanSergio'),
        caption: t('patriarchate.monasterioTrinidadSanSergio')
      }),
      React.createElement(StyledList, {
        items: t('patriarchate.flourishing', { returnObjects: true }) || []
      })
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('patriarchate.futureTitle')),
      React.createElement(StyledList, {
        items: t('patriarchate.future', { returnObjects: true }) || []
      })
    )
  );
}

module.exports = PatriarchatePage;