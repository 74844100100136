var React = require('react');

function CaptionedImage(props) {
  return React.createElement('figure', {
    style: {
      margin: '20px 0',
      textAlign: 'center'
    }
  },
    React.createElement('img', {
      src: props.src,
      alt: props.alt,
      style: {
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '8px'
      }
    }),
    React.createElement('figcaption', {
      style: {
        marginTop: '10px',
        fontStyle: 'italic',
        color: '#666'
      }
    }, props.caption)
  );
}

module.exports = CaptionedImage;
