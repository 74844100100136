var React = require('react');
var useTranslation = require('react-i18next').useTranslation;

function Audiovisual() {
  var t = useTranslation().t;

  var containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box'
  };

  var contentStyle = {
    maxWidth: '600px',
    width: '100%',
    textAlign: 'center'
  };

  var headingStyle = {
    color: '#8B0000',
    marginBottom: '20px'
  };

  var paragraphStyle = {
    color: '#A52A2A',
    marginBottom: '20px'
  };

  var imageStyle = {
    maxWidth: '300px',
    width: '100%',
    display: 'block',
    margin: '20px auto'
  };

  return React.createElement('main', { style: containerStyle },
    React.createElement('div', { style: contentStyle },
      React.createElement('h1', { style: headingStyle }, t('audiovisual.title')),
      React.createElement('p', { style: paragraphStyle }, t('audiovisual.description')),
      React.createElement('h2', { style: headingStyle }, t('audiovisual.latestVideos')),
      React.createElement('h2', { style: headingStyle }, t('audiovisual.audioArchive')),
      React.createElement('img', {
        src: 'https://cdn.pixabay.com/photo/2017/06/20/08/12/maintenance-2422173_1280.png',
        alt: t('audiovisual.underConstruction'),
        style: imageStyle
      })
    )
  );
}

module.exports = Audiovisual;
