var React = require('react');
var useTranslation = require('react-i18next').useTranslation;
var PageTitle = require('../../components/PageTitle');
var ContentSection = require('../../components/ContentSection');
var CaptionedImage = require('../../components/CaptionedImage');
var StyledList = require('../../components/StyledList');

function SaintMatronaPage() {
  var t = useTranslation().t;

  return React.createElement('div', { className: 'saint-matrona-page' },
    React.createElement(PageTitle, null, t('saintMatrona.title')),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('saintMatrona.life.title')),
      React.createElement('p', null, t('saintMatrona.life.content')),
      React.createElement(CaptionedImage, {
        src: '/images/SantaMatrona.jpg',
        alt: t('saintMatrona.imageAlt'),
        caption: t('saintMatrona.imageCaption')
      })
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('saintMatrona.work.title')),
      React.createElement('p', null, t('saintMatrona.work.content')),
      React.createElement(CaptionedImage, {
        src: '/images/SantaMatrona-foto.jpg',
        alt: t('saintMatrona.photoImageAlt'),
        caption: t('saintMatrona.photoImageCaption')
      })
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('saintMatrona.miracles.title')),
      React.createElement(StyledList, {
        items: t('saintMatrona.miracles.list', { returnObjects: true }) || []
      })
    ),
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('saintMatrona.canonization.title')),
      React.createElement('p', null, t('saintMatrona.canonization.content')),
      React.createElement(CaptionedImage, {
        src: '/images/SantaMatrona-reliquias.jpg',
        alt: t('saintMatrona.relicsImageAlt'),
        caption: t('saintMatrona.relicsImageCaption')
      })
    )
  );
}

module.exports = SaintMatronaPage;
