var React = require('react');
var useTranslation = require('react-i18next').useTranslation;
var PageTitle = require('../../components/PageTitle');
var ContentSection = require('../../components/ContentSection');

function Home() {
  var t = useTranslation().t;

  return React.createElement('div', { className: 'home-page' },
    React.createElement(ContentSection, null,
      React.createElement(PageTitle, null, t('home.about')),
      React.createElement('p', null, t('home.description')),
      React.createElement('p', null, t('parish.diocese')),
      React.createElement('p', null, t('home.conclusion'))
    )
  );
}

module.exports = Home;
