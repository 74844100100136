import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import History from './pages/History';
import Schedule from './pages/Schedule';
import Gallery from './pages/Gallery';
import Audiovisual from './pages/Audiovisual';
import Calendar from './pages/Calendar';
import Events from './pages/Events';
import StMatronaChurch from './pages/churches/StMatronaChurch';
import TrinityChurch from './pages/churches/trinityChurch';
import SaintMatronaPage from './pages/StMatrona'; // Importamos el nuevo componente
import PatriarchatePage from './pages/patriarcado'; // Importar el componente correcto
import './App.css';
import './styles/global.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Playfair Display, serif',
  },
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { t } = useTranslation();
  const navRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    function adjustContentPadding() {
      if (navRef.current && contentRef.current) {
        const navHeight = navRef.current.offsetHeight;
        contentRef.current.style.paddingTop = `${navHeight}px`;
      }
    }

    adjustContentPadding();
    window.addEventListener('resize', adjustContentPadding);

    return () => {
      window.removeEventListener('resize', adjustContentPadding);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <div className="App">
          <Navigation ref={navRef} />
          <div className="content" ref={contentRef}>
            <header className="main-header">
              <div className="header-content">
                <h1 className="parish-name" style={{ color: '#8b0000' }}>{t('parish.StMatronaChurch')}</h1>
                <p className="diocese-info">{t('parish.diocese')}</p>
              </div>
            </header>
            <main className="container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/history" element={<History />} />
                <Route path="/schedule" element={<Schedule />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/audiovisual" element={<Audiovisual />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/events" element={<Events />} />
                <Route path="/churches/stMatrona" element={<StMatronaChurch />} />
                <Route path="/churches/trinity" element={<TrinityChurch />} />
                <Route path="/saint-matrona" element={<SaintMatronaPage />} />
                <Route path="/patriarcado" element={<PatriarchatePage />} /> {/* Actualizar la ruta */}
              </Routes>
            </main>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
