var React = require('react');

function ContentSection(props) {
  return React.createElement('div', {
    className: 'content-section',
    style: {
      fontSize: '0.8em',
      backgroundColor: '#fff8e8',
      paddingTop: '3em',
      paddingBottom: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
      borderRadius: '8px',
      marginTop: '10px',
      marginBottom: '20px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'justify',
      textJustify: 'inter-word',
      hyphens: 'auto',
      webkitHyphens: 'auto',
      msHyphens: 'auto',
      wordBreak: 'break-word'
    }
  }, props.children);
}

module.exports = ContentSection;
