var React = require('react');

function PageTitle(props) {
  return React.createElement('h1', {
    className: 'page-title',
    style: {
      color: '#8b0000',
      textAlign: 'center',
      fontFamily: 'Playfair Display, serif',
      marginTop: '-20px',
      marginBottom: '20px',
      paddingTop: '0',
      fontSize: '1.8em',
      wordBreak: 'normal',
      overflowWrap: 'break-word',
      hyphens: 'manual'
    }
  }, props.children);
}

module.exports = PageTitle;
